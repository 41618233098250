/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    strong: "strong",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    ol: "ol",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "Before you begin, make sure that you have read our ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/getting-started",
    title: "Getting started"
  }, "Getting Started"), " guide, and that you have received your API key.")), "\n", React.createElement(_components.p, null, "A funding recipient is an entity that can receive funds using funding instructions. Each funding recipient includes at least one of the following:", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Funding account:"), " The funding recipient’s bank account.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Owner:"), " Individuals that are associated with the funding recipient."), "\n", React.createElement(_components.p, null, "You can create funding recipients for third parties that can’t directly process sales. For example, charities."), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Create your funding recipient."), "\n"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Create your funding recipient"), "\n", React.createElement(_components.p, null, "To create your funding recipient, send a POST request to our Funding Recipients endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/funding-recipients"
  }, "https://api.uat.payroc.com/v1/funding-recipients"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/funding-recipients"
  }, "https://api.payroc.com/v1/funding-recipients")), "\n", React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(RequestParameters, {
    operationId: "createFundingRecipient"
  })), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "Use our ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/metadata",
    title: "Metadata"
  }, "metadata"), " feature to add custom information to your request.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "createFundingRecipient"
  })), "\n", React.createElement(_components.p, null, "If your request is successful, we create the funding recipient and return a response. The response contains the following fields:"), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(ResponseBody, {
    operationId: "createFundingRecipient"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "createFundingRecipient"
  })), "\n", React.createElement(_components.h2, null, "Test cases"), "\n", React.createElement(_components.p, null, "Our integration team provides you with ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/developer-resources/test-your-integration/funding-instructions"
  }, "test cases"), " that you can run to verify that your integration works with our API."), "\n", React.createElement(_components.p, null, "For your next steps, we recommend that you follow our quick start guide about ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/quickstarts/create-funding-instructions"
  }, "sending funds to your funding recipients"), ". To view other functions about managing your funding recipients, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/resources#createFundingRecipient",
    title: "Funding Recipients"
  }, "funding recipients"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
